<template>
  <div :class="{ centerPage: showSuccess }" class="forgotPasswordContainer">
    <div class="login-body success" v-if="showSuccess && !token">
      <p>Email sent to</p>
      <p class="email">{{ email }}</p>
    </div>

    <div class="login-body" v-else-if="!token">
      <div class="login-bodyText">
        <h1>{{ forgotPasswordHeading }}</h1>
        <p>{{ forgotPasswordBody }}</p>
      </div>
      <input
        placeholder="Email"
        id="emailInput"
        name="email"
        type="text"
        v-model="email"
        @keyup.enter="resetPasswordRequest()"
        @keyup="checkIfValidFormat($event)"
        :class="{ error: isValidEmail() }"
      />

      <div class="login-footer flex-column">
        <button
          :class="{ disabled: submitButtonDisabled }"
          class="shampoo solid"
          @click="resetPasswordRequest()"
        >
          Reset password
        </button>
        <div class="internalLinkWrapper">
          <p
            class="internalLink"
            @click="$router.push({ name: 'login', params: { source: 'home' } })"
          >
            Return to login
          </p>
        </div>
      </div>
    </div>

    <div class="login-body" v-if="token">
      <h1>Reset password</h1>
      <div>
        <p>Enter your new password.</p>
        <p class="input-label-text">
          <img
            v-if="showPassword"
            @click="showPassword = !showPassword"
            src="@/assets/img/show-mid.svg"
          />
          <img
            v-else
            @click="showPassword = !showPassword"
            src="@/assets/img/hide-mid.svg"
          />
        </p>

        <input
          @keyup="resetErrors()"
          v-if="showPassword"
          name="password"
          type="text"
          v-model="password"
          placeholder="Password"
          @keyup.enter="validateLogin()"
        />
        <input
          @keyup="resetErrors()"
          v-else
          name="password"
          type="password"
          v-model="password"
          placeholder="Password"
          @keyup.enter="validateLogin()"
        />
        <p class="error" v-if="shortPassword || invalidPassword">
          Ooops, minimum 8 characters including one number!
        </p>
        <p class="error" v-if="showError">
          Something went wrong, please click the link in the email and try
          again!
        </p>
      </div>

      <div class="login-footer flex-column">
        <button class="sky solid" @click="validateLogin()">
          Reset password
        </button>

        <p
          class="link"
          @click="$router.push({ name: 'login', params: { source: 'home' } })"
        >
          Log in instead
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheForgotPasswordPage",
  data() {
    return {
      email: "",
      password: "",
      showError: false,
      showSuccess: false,
      showPassword: false,
      token: false,
      shortPassword: false,
      invalidPassword: false,
      submitButtonDisabled: true,
    };
  },
  computed: {
    forgotPasswordHeading() {
      return this.showError ? "Try again" : "Reset your password";
    },
    forgotPasswordBody() {
      return this.showError
        ? "We didn’t get a match in our system for that email."
        : "Confirm the email you signed up with and we’ll email instructions to reset your password.";
    },
  },
  methods: {
    resetErrors() {
      this.shortPassword = false;
      this.invalidPassword = false;
    },
    validateLogin() {
      if (this.password.length < 8) {
        this.shortPassword = true;
      } else {
        this.resetPassword();
      }
    },
    checkIfValidFormat(event) {
      const password = event.target.value;
      if (password.length > 8 && password.includes("@")) {
        this.submitButtonDisabled = false;
      } else {
        this.submitButtonDisabled = true;
      }
    },
    isValidEmail() {
      return this.showError;
    },
    resetPassword() {
      (this.invalidPassword = false), (this.shortPassword = false);

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            ResetPassword(resetToken:"${this.$router.currentRoute.query.token}",newPassword:"${this.password}")
          }
          `,
        },
      })
        .then((result) => {
          if (result.data.data.ResetPassword) {
            this.$router.push({ name: "login", params: { source: "home" } });
          } else if (result.data.data.ResetPassword == null) {
            if (
              result.data.errors[0].message ==
              "Minimum eight characters, at least one letter and one number"
            ) {
              this.invalidPassword = true;
            } else if (
              result.data.errors[0].errorCode == "INTERNAL_SERVER_ERROR"
            ) {
              this.showError = true;
            } else if (result.data.errors[0].message == "Invalid token") {
              this.showError = true;
            }
          }
        })
        .catch(() => {});
    },
    resetPasswordRequest() {
      this.showError = false;

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation {
            RequestPasswordReset(email:"${this.email}")
          }
          `,
        },
      })
        .then((result) => {
          if (result.data.data.RequestPasswordReset == null) {
            this.showError = true;
          } else {
            this.showSuccess = true;
          }
        })
        .catch(() => {});
    },
  },
  mounted() {
    if (typeof this.$router.currentRoute.query.token !== "undefined") {
      this.token = true;
    }
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 32px;
  line-height: 32px;
  @include Gilroy-Bold;
  margin-bottom: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.forgotPasswordContainer {
  height: calc(100vh - 50px);
  display: flex;
  align-items: flex-end;
  width: 92%;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 10px;
  text-align: center;
  &.centerPage {
    align-items: center;
  }
}

.login-body {
  width: 100%;
  .login-bodyText {
    margin-bottom: 32px;
  }
  .email {
    @include Gilroy-Bold;
  }
  &.success {
    p {
      margin: 0;
    }
  }
}

.error {
  color: #ff0064;
  border: 1px solid #ff0064;
}

input {
  background: white;
  border-radius: 6px;
  border: 1px solid $mouse;
  height: 44px;
  font-size: 16px;
  @include Gilroy-SemiBold;
  outline: none;
  padding-left: 15px;
  width: 100%;
  margin-bottom: 32px;
}
button {
  width: 100%;
  margin-bottom: 32px;
}

a {
  color: $sky;
  @include Gilroy-Medium;
}

.input-label-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.internalLinkWrapper {
  display: flex;
  justify-content: center;
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .forgotPasswordContainer {
    align-items: center;
    margin-top: -120px;
  }
}

@media (min-width: 992px) {
}
</style>
